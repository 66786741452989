import React from 'react';
import { shallowEqual } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ThemeProvider, useRootTheme } from '@cognitiv/cassiopeia-ui';
import { selectSettings } from 'ducks/settings/selectors';
import { selectUserAuthentication } from 'ducks/user/selectors';
import { Confirm } from 'modals/utility/Confirm';
import { Error } from 'modals/utility/Error';
import { Warning } from 'products/public/components/warning/Warning';
import { TITAN } from 'routes';
import { useAppSelector } from 'store/hooks';

import { LOADING_VARIANTS } from 'components/layout/loading/enums';
import { Loading } from 'components/layout/loading/Loading';
import { Snackbar } from 'components/layout/snackbar/Snackbar';

import cn from 'templates/Template.module.scss';

export default () => {
  const location = useLocation();
  const { loading, theme: theme_variant } = useAppSelector(selectSettings, shallowEqual);
  useRootTheme(theme_variant, 'luna');

  const authenticated = useAppSelector(selectUserAuthentication, shallowEqual);

  if (authenticated) {
    return <Navigate to={location.state?.referer || `${TITAN.APPLICATION.path}/${TITAN.ADVERTISERS.path}`} />;
  }

  return (
    <ThemeProvider theme_variant={theme_variant}>
      <div className={cn.public}>
        <Outlet />
        <Snackbar />
        <Warning />
        <Error />
        <Confirm />
        {loading && <Loading variant={LOADING_VARIANTS.DARK} />}
      </div>
    </ThemeProvider>
  );
};
