import titanAdvertiserSlice from 'products/titan/operators/advertiser/slices';
import titanAdvertisersSlice from 'products/titan/operators/advertisers/slices';
import titanCampaignFlightSlice from 'products/titan/operators/campaign-flight/slices';
import titanCreativeTypesListSlice from 'products/titan/operators/creative-types-list/slices';
import titanInventorySourcesListSlice from 'products/titan/operators/inventory-sources-list/slices';
import titanPartnersSlice from 'products/titan/operators/partners/slices';

export const titan = {
  titan_advertiser: titanAdvertiserSlice,
  titan_advertisers: titanAdvertisersSlice,
  titan_creative_types_list: titanCreativeTypesListSlice,
  titan_inventory_sources_list: titanInventorySourcesListSlice,
  titan_partners: titanPartnersSlice,
  titan_campaign_flight: titanCampaignFlightSlice,
};
