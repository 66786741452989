import { TitanFlight } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_flight_default } from 'products/titan/operators/campaign-flight/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanFlight = { ...titan_flight_default };

export const titanCampaignFlightSlice = createSlice({
  name: SLICE.TITAN_CAMPAIGN_FLIGHT,
  initialState,
  reducers: {
    clearTitanCampaignFlight: () => initialState,
    setTitanCampaignFlight: (_, action: PayloadAction<TitanFlight>) => action.payload,
  },
});

export const { clearTitanCampaignFlight, setTitanCampaignFlight } = titanCampaignFlightSlice.actions;

export default titanCampaignFlightSlice.reducer;
