export enum ORION_PREVIEW_COLUMN {
  URL = 1,
  ACTIONS = 2,
  RELEVANCE = 3,
  AVAILS = 4,
  AGE = 5,
}

export enum ORION_PREVIEW_SORT {
  AVAILS = 1,
  RELEVANT = 2,
  AGE = 3,
}

export enum ORION_PREVIEW_SORT_NAME {
  MOST_RELEVANT = 'Most Relevant',
  LEAST_RELEVANT = 'Least Relevant',
  MOST_AVAILS = 'Most Avails',
  LEAST_AVAILS = 'Least Avails',
  NEWEST = 'Newest',
  OLDEST = 'Oldest',
}

export enum REFERENCE_TEXT_TYPE_ID {
  PROMPT = 1,
  URL = 2,
}

export enum OPERATOR_ID {
  EQUAL_TO = 1,
  NOT_EQUAL_TO = 2,
  GREATER_THAN = 3,
  GREATER_THAN_EQUAL_TO = 4,
  LESS_THAN = 5,
  LESS_THAN_EQUAL_TO = 6,
  IN = 7,
}

export enum ATTRIBUTE_CATEGORY_TYPE {
  INCLUSIVITY = 1,
  ETHNICITY = 2,
  AGE = 3,
  SEX = 4,
  FOREIGN_LANGUAGE = 5,
  VIEWABILITY = 6,
  SENTIMENT = 7,
  CTR = 8,
  VCR = 9,
  AVAILS = 10,
  LAST_SEEN = 11,
  TOP_LEVEL_DOMAINS = 12,
}

export enum ATTRIBUTE_GROUP_ID {
  INCLUSIVITY = 58,
  '19_29' = 62,
  '30_39' = 63,
  '40_49' = 64,
  '50_59' = 65,
  '60_64' = 66,
  '65+' = 67,
  FEMALE = 68,
  MALE = 69,
  AMERICAN_INDIAN_ALASKA_NATIVE = 90,
  ASIAN = 87,
  BLACK_AFRICAN_AMERICAN = 86,
  HISPANIC_LATINO = 89,
  PACIFIC_ISLANDER = 91,
  WHITE = 88,
  FOREIGN_LANGUAGE = 92,
  SENTIMENT = 77,
  VIEWABILITY = 145,
  VCR = 96,
  CTR = 147,
  AVAILS = 136,
  LAST_SEEN = 142,
  DOMAIN_EXTENSION = 154,
}

export enum FOREIGN_LANGUAGE {
  ENGLISH = 1,
  SPANISH = 2,
  JAPANESE = 3,
  CHINESE = 4,
  FRENCH = 5,
  PORTUGUESE = 6,
  GERMAN = 7,
  DUTCH = 8,
  ITALIAN = 9,
  DANISH = 10,
  SWEDISH = 11,
  NORWEGIAN = 12,
  FINNISH = 13,
}

export enum PULSE_VARIANT {
  BLUE = 'blue',
  ORANGE = 'orange',
  RED = 'red',
  PINK = 'pink',
}

export enum ORION_SEGMENT_OPTION_ID {
  EDIT = 1,
}
