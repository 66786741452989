import { selectSettings } from 'ducks/settings/selectors';
import {
  DEMOGRAPHIC_BLUE_DARK_MODE,
  DEMOGRAPHIC_BLUE_LIGHT_MODE,
  DEMOGRAPHIC_PINK,
} from 'products/scorpio/components/insights/demographics/constants';
import { useAppSelector } from 'store/hooks';
import { colors } from 'theme/colors';

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
}
const light = {
  breadcrumb: {
    field: {
      border: { normal: 'none', hover: 'none', focus: 'none', disabled: 'none' },
      background: { normal: colors.navy[9], hover: colors.navy[8], focus: colors.navy[10], disabled: colors.navy[9] },
      color: { normal: colors.neutral[1], hover: colors.neutral[1], focus: colors.neutral[1], disabled: colors.neutral[1] },
    },
    select: { background: colors.neutral[1] },
  },
  textarea: { field: { background: { normal: colors.neutral[3] } } },
  banner: {
    banner: {
      general: {
        color: colors.neutral[10],
        description_color: colors.neutral[10],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.neutral[3],
      },
      border: {
        normal: `1px solid ${colors.neutral[3]}`,
      },
    },
  },
  banner_action: {
    banner: {
      general: {
        color: colors.neutral[10],
        description_color: colors.neutral[10],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.neutral[3],
        hover: colors.neutral[4],
      },
      border: {
        normal: `1px solid ${colors.neutral[3]}`,
        hover: `1px solid ${colors.neutral[4]}`,
      },
    },
  },
  card_label: {
    label: { font_weight: 500 },
    font: { general: { font_size_normal: '15px' } },
    field: { color: { normal: colors.neutral[10] } },
  },
  modal_label: {
    font: { general: { font_size_normal: '17px' } },
    label: { font_weight: 500 },
    field: { color: { normal: colors.neutral[10] } },
  },
  information_label: {
    font: { general: { font_size_normal: '12px' } },
    label: { font_weight: 400 },
    field: { color: { normal: colors.neutral[9] } },
  },
  dropdown_menu: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.neutral[3], hover: colors.neutral[4], focus: colors.neutral[4] },
      general: { padding: '0px 12px' },
    },
    select: { background: colors.neutral[1] },
  },
  dropdown_icon_menu: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.neutral[3], hover: colors.neutral[4], focus: colors.neutral[4] },
    },
    select: { background: colors.neutral[1] },
  },
  dropdown_calendar: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.neutral[3], hover: colors.neutral[4], focus: colors.neutral[4] },
      general: { padding: '0px 12px' },
    },
    select: { background: colors.neutral[1], padding: 0, max_height: 'fit-content' },
  },
  generic: {
    on_color: colors.platinum[9],
    off_color: colors.navy[8],
    banner_primary: colors.blue[6],
    banner_secondary: colors.neutral[10],
    cell_disabled_background: colors.neutral[2],
  },
  chart: {
    color: colors.text_light.primary,
    tooltip_color: colors.neutral[10],
    tooltip_background: colors.neutral[1],
    background: colors.neutral[1],
    empty_background: colors.neutral[3],
    empty_text: colors.neutral[5],
    disabled_text: colors.neutral[7],
  },
  framework: {},
  insights: {
    background_pink: DEMOGRAPHIC_PINK,
    background_blue: DEMOGRAPHIC_BLUE_LIGHT_MODE,
    label_color: colors.neutral[10],
  },
};
const dark = {
  breadcrumb: {
    field: {
      border: { normal: 'none', hover: 'none', focus: 'none', disabled: 'none' },
      background: { normal: colors.platinum[10], hover: colors.platinum[9], focus: colors.platinum[9], disabled: colors.platinum[10] },
      color: { normal: colors.neutral[1], hover: colors.neutral[1], focus: colors.neutral[1], disabled: colors.neutral[1] },
    },
    select: { background: colors.neutral[11] },
  },
  textarea: { field: { background: { normal: colors.platinum[9] } } },
  banner: {
    banner: {
      general: {
        color: colors.neutral[3],
        description_color: colors.neutral[3],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.platinum[9],
      },
      border: {
        normal: `1px solid ${colors.platinum[9]}`,
      },
    },
  },
  banner_action: {
    banner: {
      general: {
        color: colors.neutral[3],
        description_color: colors.neutral[3],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.platinum[9],
        hover: colors.platinum[8],
      },
      border: {
        normal: `1px solid ${colors.platinum[9]}`,
        hover: `1px solid ${colors.platinum[8]}`,
      },
    },
  },
  card_label: {
    label: { font_weight: 500 },
    font: { general: { font_size_normal: '15px' } },
    field: { color: { normal: colors.neutral[3] } },
  },
  modal_label: {
    font: { general: { font_size_normal: '17px' } },
    label: { font_weight: 500 },
    field: { color: { normal: colors.neutral[3] } },
  },
  information_label: {
    font: { general: { font_size_normal: '12px' } },
    label: { font_weight: 400 },
    field: { color: { normal: colors.neutral[5] } },
  },
  dropdown_menu: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.platinum[9], hover: colors.platinum[8], focus: colors.platinum[9] },
      general: { padding: '0px 12px' },
    },
    option: {
      background: {
        normal: colors.neutral[10],
        hover: colors.platinum[10],
        focus: colors.platinum[10],
        pressed: colors.platinum[7],
        selected: colors.blue[10],
      },
    },
    select: { background: colors.neutral[10] },
  },
  dropdown_icon_menu: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.platinum[9], hover: colors.platinum[8], focus: colors.platinum[9] },
    },
    option: {
      background: {
        normal: colors.neutral[10],
        hover: colors.platinum[10],
        focus: colors.platinum[10],
        pressed: colors.platinum[7],
        selected: colors.blue[10],
      },
    },
    select: { background: colors.neutral[10] },
  },
  dropdown_calendar: {
    field: {
      border: {
        normal: '1px solid transparent',
        hover: '1px solid transparent',
      },
      background: { normal: colors.platinum[9], hover: colors.platinum[8], focus: colors.platinum[9] },
      general: { padding: '0px 12px' },
    },
    option: {
      background: {
        normal: colors.neutral[10],
        hover: colors.platinum[10],
        focus: colors.platinum[10],
        pressed: colors.platinum[7],
        selected: colors.blue[10],
      },
    },
    select: { background: colors.neutral[10], padding: 0, max_height: 'fit-content' },
  },
  generic: {
    on_color: colors.platinum[9],
    off_color: colors.navy[8],
    banner_primary: colors.blue[6],
    banner_secondary: colors.neutral[1],
    cell_disabled_background: colors.platinum[10],
  },
  chart: {
    color: colors.text_dark.primary,
    tooltip_color: colors.neutral[3],
    tooltip_background: colors.neutral[11],
    background: colors.platinum[9],
    empty_background: colors.platinum[9],
    empty_text: colors.neutral[5],
    disabled_text: colors.neutral[7],
  },
  framework: {
    primary: {
      color: {
        normal: colors.text_light.invert,
        hover: colors.text_light.invert,
        focus: colors.blue[6],
        pressed: colors.text_light.invert,
        active: colors.text_light.invert,
        disabled: colors.pink[11],
        error: colors.red[6],
      },
      background: {
        normal: colors.pink[6],
        hover: colors.pink[7],
        focus: colors.pink[6],
        pressed: colors.pink[5],
        active: colors.pink[7],
        disabled: colors.pink[10],
        error: colors.neutral[1],
      },
    },
    secondary: {
      color: {
        normal: colors.text_light.contrast,
        hover: colors.text_light.contrast,
        focus: colors.blue[6],
        pressed: colors.text_light.contrast,
        active: colors.text_light.contrast,
        disabled: colors.platinum[8],
        error: colors.red[6],
      },
      background: {
        normal: colors.pink[1],
        hover: colors.pink[2],
        focus: colors.pink[3],
        pressed: colors.pink[3],
        active: colors.pink[3],
        disabled: colors.neutral[3],
        error: colors.neutral[1],
      },
    },
    tertiary: {
      color: {
        normal: colors.text_dark.primary,
        hover: colors.text_dark.primary,
        focus: colors.blue[6],
        pressed: colors.text_dark.primary,
        active: colors.text_dark.primary,
        disabled: colors.platinum[8],
        error: colors.red[6],
      },
      background: {
        normal: colors.platinum[9],
        hover: colors.platinum[8],
        focus: colors.platinum[7],
        pressed: colors.platinum[7],
        active: colors.platinum[7],
        disabled: colors.platinum[10],
        error: colors.neutral[1],
      },
    },
    subtle: {
      color: {
        normal: colors.text_dark.primary,
        hover: colors.text_dark.primary,
        focus: colors.blue[6],
        active: colors.text_dark.primary,
        pressed: colors.text_dark.primary,
        disabled: colors.platinum[8],
        error: colors.red[6],
      },
      background: {
        normal: colors.blue_grey[10],
        hover: colors.platinum[10],
        focus: colors.platinum[9],
        active: colors.platinum[9],
        pressed: colors.platinum[9],
        disabled: colors.blue_grey[10],
        error: colors.neutral[1],
      },
    },
    plain: {
      color: {
        normal: colors.text_dark.invert,
        hover: colors.blue[8],
        focus: colors.blue[6],
        pressed: colors.blue[5],
        active: colors.blue[5],
        disabled: colors.platinum[9],
        error: colors.red[6],
      },
      background: {
        normal: 'transparent',
        hover: 'transparent',
        focus: 'transparent',
        active: 'transparent',
        pressed: 'transparent',
        disabled: 'transparent',
        error: 'transparent',
      },
    },
    link: {
      color: {
        normal: colors.blue[6],
        hover: colors.blue[8],
        focus: colors.blue[5],
        pressed: colors.blue[5],
        active: colors.blue[5],
        disabled: colors.neutral[7],
        error: colors.red[6],
      },
      background: {
        normal: 'transparent',
        hover: 'transparent',
        focus: 'transparent',
        active: 'transparent',
        pressed: 'transparent',
        disabled: 'transparent',
        error: 'transparent',
      },
    },
    field: {
      general: {
        cursor: colors.blue[6],
      },
      color: {
        selected: colors.blue[6],
      },
      border: {
        focus: `1px solid ${colors.blue[6]}`,
        pressed: `1px solid ${colors.blue[6]}`,
        selected: `1px solid ${colors.blue[1]}`,
      },
      background: {
        normal: colors.neutral[10],
        hover: colors.platinum[10],
        focus: colors.neutral[10],
        pressed: colors.platinum[10],
        selected: colors.platinum[10],
        disabled: colors.platinum[10],
        error: colors.neutral[10],
      },
    },
    option: {
      background: {
        normal: colors.neutral[10],
        hover: colors.platinum[10],
        selected: colors.blue[10],
      },
      border: {
        focus: `1px solid ${colors.blue[6]}`,
        selected: `1px solid ${colors.blue[6]}`,
      },
    },
    banner: {
      description_color: colors.text_dark.invert,
    },
    calendar: {
      background: {
        primary: colors.blue[7],
        secondary: colors.blue[6],
        tertiary: colors.platinum[10],
      },
    },
  },
  insights: {
    background_pink: DEMOGRAPHIC_PINK,
    background_blue: DEMOGRAPHIC_BLUE_DARK_MODE,
    label_color: colors.neutral[1],
  },
};
export const useTheme = () => {
  const { theme } = useAppSelector(selectSettings);

  return { theme: theme === THEME.LIGHT ? light : dark, theme_variant: theme, is_light: theme === THEME.LIGHT };
};
