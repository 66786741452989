import { createSelector } from '@reduxjs/toolkit';
import { User } from 'ducks/user/types';
import { RootState } from 'store';

const userSlice = (state: RootState) => state.user;

export const selectUser = createSelector([userSlice], (state): User => state);

export const selectUserAuthentication = createSelector([userSlice], (state): boolean => {
  return state && Boolean(state?.private_key) && Boolean(state?.public_key);
});
