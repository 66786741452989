import { OrionCampaign } from '@cognitiv/galaxy-api';

export const orion_campaign_default: OrionCampaign = {
  campaign_id: 0,
  campaign_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  daily_spend_usd: 0,
  daily_spend_usd_formatted: '',
  total_spend_usd: 0,
  total_spend_usd_formatted: '',
  created_by: '',
  created_date: '',
  campaign_state_id: 0,
  campaign_state_name: '',
  segment_count: 0,
  segment_count_formatted: '',
  deal_count: 0,
  deal_count_formatted: '',
  opportunity_size_usd: 0,
  opportunity_size_usd_formatted: '',
  creative_types: '',
  kpi_benchmark: null,
  delivery_source_id: 0,
  delivery_source_name: '',
  conversion_source_id: 0,
  conversion_source_name: '',
  uuid: '',
  kpi_type_id: null,
  kpi_type_name: '',
  kpi_unit_id: null,
  kpi_unit_name: '',
  kpi_benchmark_abbr_formatted: '',
  kpi_benchmark_formatted: '',
  created_by_user_id: 0,
};
